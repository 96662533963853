import { Dispatch, SetStateAction } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    Button,
    Col,
    Form,
    FormControl,
    InputGroup,
    OverlayTrigger,
    Spinner,
    Stack,
    Tooltip,
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'

import { RequestEmailChangeInput } from './types'
import { ResponseError, ResponseNotification } from 'common/types'
import { useRequestEmailChangeMutation } from 'app/api'
import { cleanAuthState, selectCurrentUserEmail } from 'features/auth/authSlice'
import { requestEmailChangeValidationSchema } from './validationSchema'
import { formatRequestEmailChangeError } from '../../common/error'

interface RequestEmailChangeFormProps {
    setResponseNotification: Dispatch<
        SetStateAction<ResponseNotification | null>
    >
    renderForm: boolean
    setRenderForm: Dispatch<SetStateAction<boolean>>
}

const RequestEmailChangeForm = ({
    setResponseNotification,
    renderForm,
    setRenderForm,
}: RequestEmailChangeFormProps) => {
    const dispatch = useDispatch()

    const email = useSelector(selectCurrentUserEmail)

    const [requestEmailChange] = useRequestEmailChangeMutation()

    const {
        setFocus,
        register,
        reset,
        handleSubmit,
        getValues,
        formState: { errors, isSubmitting, isSubmitted },
    } = useForm<RequestEmailChangeInput>({
        defaultValues: { newEmail: '' },
        resolver: yupResolver(requestEmailChangeValidationSchema),
    })
    const onSubmit = async ({ newEmail }: RequestEmailChangeInput) => {
        if (email) {
            try {
                await requestEmailChange({ email, newEmail })
                    .unwrap()
                    .catch(err => {
                        throw formatRequestEmailChangeError(err, newEmail)
                    })

                setResponseNotification({
                    status: 200,
                    message: `Es wurde eine Email mit einem Bestätigungslink an die Email Adresse "${newEmail}" gesendet. Bitte prüfen Sie Ihr E-Mail-Postfach.`,
                })

                setRenderForm(false)
                reset()
            } catch (error) {
                const responseError = (error as ResponseError).data.error
                setResponseNotification(responseError)
                setFocus('newEmail')
            }
        } else {
            dispatch(cleanAuthState())
        }
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction='vertical' gap={4} className='mb-4'>
                <Stack direction='horizontal'>
                    <InputGroup className='w-100'>
                        <InputGroup.Text>
                            <i>
                                <FontAwesomeIcon icon={faUser} />
                            </i>
                        </InputGroup.Text>
                        <FormControl
                            type='email'
                            aria-label='Nutzer Email Feld'
                            value={email}
                            disabled
                        />
                    </InputGroup>
                    <Col className='ms-auto align-self-end ps-3'>
                        <Button
                            type='button'
                            variant='secondary'
                            disabled={renderForm}
                            onClick={() => {
                                reset()
                                setRenderForm(true)
                            }}
                        >
                            Ändern
                        </Button>
                    </Col>
                </Stack>
                {renderForm && (
                    <>
                        <InputGroup className='w-100'>
                            <InputGroup.Text
                                className={
                                    isSubmitted
                                        ? errors.newEmail?.message
                                            ? 'ds-form-is-invalid'
                                            : 'ds-form-is-valid'
                                        : ''
                                }
                            >
                                {errors.newEmail ? (
                                    <OverlayTrigger
                                        placement='top'
                                        overlay={props => (
                                            <Tooltip
                                                id='tooltip-new-email-account'
                                                {...props}
                                            >
                                                {errors.newEmail?.message}
                                            </Tooltip>
                                        )}
                                    >
                                        <i>
                                            <FontAwesomeIcon icon={faUser} />
                                        </i>
                                    </OverlayTrigger>
                                ) : (
                                    <i>
                                        <FontAwesomeIcon icon={faUser} />
                                    </i>
                                )}
                            </InputGroup.Text>
                            <FormControl
                                type='email'
                                isValid={getValues('newEmail') !== ''}
                                isInvalid={
                                    errors.newEmail?.message ? true : false
                                }
                                placeholder='Neue Email-Adresse'
                                aria-label='Neues Email Feld'
                                {...register('newEmail')}
                            />
                        </InputGroup>
                        <Stack direction='horizontal' gap={2} className='w-100'>
                            <Button
                                type='submit'
                                variant='primary'
                                aria-label='Änderung der Email-Adresse speichern'
                                className='w-50'
                            >
                                <Stack
                                    direction='horizontal'
                                    gap={2}
                                    className='justify-content-center'
                                >
                                    {isSubmitting && (
                                        <Spinner animation='border' size='sm' />
                                    )}
                                    <span>Speichern</span>
                                </Stack>
                            </Button>
                            <Button
                                type='submit'
                                variant='secondary'
                                onClick={() => {
                                    setRenderForm(false)
                                    setResponseNotification(null)
                                    reset()
                                }}
                                aria-label='Änderunge der Email-Adresse abbrechen'
                                className='w-50'
                            >
                                Abbrechen
                            </Button>
                        </Stack>
                    </>
                )}
            </Stack>
        </Form>
    )
}

export default RequestEmailChangeForm
