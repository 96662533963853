import { Dispatch, SetStateAction, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Button,
    Col,
    Container,
    Image,
    Modal,
    Row,
    Stack,
} from 'react-bootstrap'

import { ResponseNotification } from 'common/types'
import ChangePasswordForm from './ChangePasswordForm'
import RequestEmailChangeForm from './RequestEmailChangeForm'
import ResponseAlert from 'common/alert/ResponseAlert'
import { cleanAuthState, selectCurrentProvider } from 'features/auth/authSlice'
import api from '../../app/api'

import settingsImage from 'assets/images/icon-settings.svg'

interface AccountPageProps {
    showModal: boolean
    setShowModal: Dispatch<SetStateAction<boolean>>
}

const Account = ({ showModal, setShowModal }: AccountPageProps) => {
    const dispatch = useDispatch()

    const [
        renderRequestEmailChangeForm,
        setRenderRequestEmailChangeForm,
    ] = useState<boolean>(false)
    const [renderChangePasswordForm, setRenderChangePasswordForm] = useState<
        boolean
    >(false)

    const [
        responseNotification,
        setResponseNotification,
    ] = useState<ResponseNotification | null>(null)

    const provider = useSelector(selectCurrentProvider)

    const handleClose = () => {
        setShowModal(false)
        setRenderRequestEmailChangeForm(false)
        setRenderChangePasswordForm(false)
        setResponseNotification(null)
    }

    return (
        <Modal className='ds-modal' show={showModal} onHide={handleClose}>
            <Modal.Header closeButton onClick={handleClose}>
                <div className='ds-icon-modal ms-auto flex-shrink-1'>
                    <div className='ds-icon-modal-center'>
                        <div className='ds-card-icon d-flex align-items-center justify-content-center'>
                            <Image
                                className='ds-modal-accounticon-icon'
                                src={settingsImage}
                                alt='Konto Einstelungen'
                            />
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='w-100 mb-4'>
                    <h4 className='text-break fw-bold'>Mein Account </h4>
                    <div className='text-color-n3 ds-card-room-name'>
                        {provider?.name}
                    </div>
                </div>
                {responseNotification && (
                    <Row className='justify-content-center my-4'>
                        <Col>
                            <ResponseAlert
                                status={responseNotification.status}
                                message={responseNotification.message}
                            />
                        </Col>
                    </Row>
                )}
                <Stack gap={4}>
                    <RequestEmailChangeForm
                        setResponseNotification={setResponseNotification}
                        renderForm={renderRequestEmailChangeForm}
                        setRenderForm={setRenderRequestEmailChangeForm}
                    />
                    <ChangePasswordForm
                        setResponseNotification={setResponseNotification}
                        renderForm={renderChangePasswordForm}
                        setRenderForm={setRenderChangePasswordForm}
                    />
                </Stack>
            </Modal.Body>
            <Modal.Footer>
                <Container fluid>
                    <Stack className='w-100' gap={2}>
                        <Button
                            type='button'
                            variant='primary'
                            className='px-2 w-100 mb-2'
                            onClick={() => {
                                handleClose()
                                dispatch(api.util.resetApiState())
                                dispatch(cleanAuthState())
                            }}
                        >
                            Ausloggen
                        </Button>
                        <Button
                            type='button'
                            variant='secondary'
                            className='px-4 w-100'
                            onClick={handleClose}
                        >
                            Einstellungen schließen
                        </Button>
                    </Stack>
                </Container>
            </Modal.Footer>
        </Modal>
    )
}

export default Account
