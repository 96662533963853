import { LoginInput } from '../features/auth/types'
import { ResponseError } from './types'

export const formatLoginError = (error: any, loginData: LoginInput) => {
    if (error.data && error.data.error && error.data.error.message) {
        let message: string
        switch (error.data.error.message) {
            case 'User does not exist.':
                message = `Der Benutzer mit der Email Adresse "${loginData.email}" konnte nicht gefunden werden.`
                break
            case 'Incorrect username or password.':
            case 'Password attempts exceeded':
                message = `Das eingegebene Passwort ist fehlerhaft. Bitte prüfen Sie Ihre Eingaben und versuchen es erneut.`
                break
            default:
                message = `Beim Login ist ein Fehler aufgetreten. Bitte prüfen Sie Ihre Eingaben und versuchen es erneut.`
                break
        }
        return {
            data: {
                error: {
                    message: message,
                    status: error.status,
                },
            },
        } as ResponseError
    }
    return error as ResponseError
}

export const formatConfirmEmailChangeError = (error: any) => {
    if (error.data && error.data.error && error.data.error.message) {
        let message: string
        switch (error.data.error.message) {
            case 'An account with the given email already exists.':
                message = `Es ist bereits ein Benutzer mit der neuen Email Adresse vorhanden.`
                break
            case 'Attempt limit exceeded, please try after some time.':
            default:
                message = `Bei der Bestätigung der Email Änderung ist ein Fehler aufgetreten.`
                break
        }
        return {
            data: {
                error: {
                    message: message,
                    status: error.status,
                },
            },
        } as ResponseError
    }
    return error as ResponseError
}

export const formatRequestEmailChangeError = (error: any, newEmail: string) => {
    if (error && error.data && error.data && error.data.message) {
        let message: string
        switch (error.data.message) {
            case 'User already exists.':
                message = `Die Email Adresse "${newEmail}" ist bereits vorhanden.`
                break
            default:
                message =
                    'Bei der Anfrage der Email Änderung ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.'
                break
        }
        return {
            data: {
                error: {
                    message: message,
                    status: error.status,
                },
            },
        } as ResponseError
    }

    return error as ResponseError
}

export const formatRequestPasswordChangeError = (error: any, email: string) => {
    if (error && error.data && error.data.error && error.data.error.message) {
        let message: string
        switch (error.data.error.message) {
            case 'Username/client id combination not found.':
                message = `Die Email Adresse "${email}" konnte nicht gefunden werden. Bitte prüfen Sie die Eingabe und versuchen es erneut.`
                break
            default:
                message =
                    'Bei der Anfrage der "Passwort vergessen" Funktion ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.'
                break
        }
        return {
            data: {
                error: {
                    message: message,
                    status: error.status,
                },
            },
        } as ResponseError
    }

    return error as ResponseError
}

export const formatPasswordChangeError = (error: any) => {
    if (error && error.data && error.data.error && error.data.error.message) {
        let message: string
        switch (error.data.error.message) {
            case 'Incorrect username or password.':
                message =
                    'Das eingegebene Passwort ist fehlerhaft. Bitte prüfen Sie Ihre Eingaben und versuchen es erneut.'
                break
            default:
                message =
                    'Bei der Änderung des Passwortes ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.'
                break
        }
        return {
            data: {
                error: {
                    message: message,
                    status: error.status,
                },
            },
        } as ResponseError
    }

    return error as ResponseError
}
