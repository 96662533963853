import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import { AuthChallenge, ResponseNotification } from 'common/types'
import ConfirmEmailChangeForm from './ConfirmEmailChangeForm'
import ConfirmPasswordChangeForm from './ConfirmPasswordChangeForm'
import LoginForm from './LoginForm'
import RequestPasswordChangeForm from './RequestPasswordChangeForm'
import SetFirstPasswordForm from './SetFirstPasswordForm'
import ResponseAlert from 'common/alert/ResponseAlert'
import { selectCurrentUser } from './authSlice'

const LoginPage = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const emailParam = searchParams.get('email')
    const action = searchParams.get('action')
    const confirmationCode = searchParams.get('confirmationCode')

    const [email, setEmail] = useState<string | null>(emailParam)

    const [
        showRequestPasswordChangeForm,
        setShowRequestPasswordChangeForm,
    ] = useState<boolean>(false)
    const [
        showConfirmPasswordChangeForm,
        setShowConfirmPasswordChangeForm,
    ] = useState<boolean>(action === 'CONFIRM_PASSWORD_CHANGE')
    const [
        showConfirmEmailChangeForm,
        setShowConfirmEmailChangeForm,
    ] = useState<boolean>(action === 'CONFIRM_EMAIL_CHANGE')

    const [authChallenge, setAuthChallenge] = useState<AuthChallenge | null>(
        null
    )
    const [
        responseNotification,
        setResponseNotification,
    ] = useState<ResponseNotification | null>(null)

    const user = useSelector(selectCurrentUser)

    useEffect(() => {
        if (user && !action) {
            navigate('/')
        }
    })

    return (
        <>
            <header className='container ds-bg-secondary'>
                <Row>
                    <h4 className='ds-company-name'>Task Manager</h4>
                    <span className='pb-6'>
                        Reinigung, Hausmeister, Wäscherei
                    </span>
                </Row>
            </header>
            <Container className='ds-bg-secondary pt-5 pb-4'>
                <Row className='justify-content-center'>
                    <Col className='col-12 col-sm-10 col-md-8'>
                        <Card className='card ds-card mb-4 ds-card-qr-scan'>
                            <div className='d-flex'>
                                <div className='ms-auto flex-shrink-1'>
                                    <div className='ds-card-icon ds-card-icon-qr-scan d-flex align-items-center justify-content-center'>
                                        <div className='ds-card-icon-qr-scan-border'>
                                            <i>
                                                <FontAwesomeIcon
                                                    icon={faCamera}
                                                    size='1x'
                                                />
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className='ds-padding-left-0-5 w-100 align-self-center'>
                                    <span className='text-break fw-bold'>
                                        Für Bearbeiter - QR Code der Wohneinheit
                                        scannen
                                    </span>
                                </div>
                                <div className='ms-auto p-3 align-self-center'>
                                    <a
                                        href={
                                            process.env.REACT_APP_MOBILE_URL ??
                                            'http://localhost:3000'
                                        }
                                        aria-label='Sich mit QR-Code einloggen'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <i className='text-color-n3'>
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                            />
                                        </i>
                                    </a>
                                </div>
                            </div>
                        </Card>
                    </Col>
                </Row>
                {responseNotification && (
                    <Row className='justify-content-center'>
                        <Col className='col-12 col-sm-10 col-md-8'>
                            <ResponseAlert
                                status={responseNotification.status}
                                message={responseNotification.message}
                            />
                        </Col>
                    </Row>
                )}
                {!authChallenge ? (
                    <LoginForm
                        setResponseNotification={setResponseNotification}
                        email={email}
                        setEmail={setEmail}
                        setAuthChallenge={setAuthChallenge}
                        setShowRequestPasswordChangeForm={
                            setShowRequestPasswordChangeForm
                        }
                    />
                ) : (
                    email && (
                        <SetFirstPasswordForm
                            email={email}
                            authChallenge={authChallenge}
                            setAuthChallenge={setAuthChallenge}
                            setResponseNotification={setResponseNotification}
                        />
                    )
                )}
            </Container>
            <RequestPasswordChangeForm
                showModal={showRequestPasswordChangeForm}
                setShowModal={setShowRequestPasswordChangeForm}
                setLoginResponseNotification={setResponseNotification}
            />
            {email && confirmationCode && (
                <>
                    {showConfirmEmailChangeForm && (
                        <ConfirmEmailChangeForm
                            email={email}
                            confirmationCode={confirmationCode}
                            showModal={showConfirmEmailChangeForm}
                            setShowModal={setShowConfirmEmailChangeForm}
                            setLoginResponseNotification={
                                setResponseNotification
                            }
                        />
                    )}
                    {showConfirmPasswordChangeForm && (
                        <ConfirmPasswordChangeForm
                            email={email}
                            confirmationCode={confirmationCode}
                            showModal={showConfirmPasswordChangeForm}
                            setShowModal={setShowConfirmPasswordChangeForm}
                            setLoginResponseNotification={
                                setResponseNotification
                            }
                        />
                    )}
                </>
            )}
        </>
    )
}

export default LoginPage
