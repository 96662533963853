import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'

import {
    ChangePasswordRequest,
    ConfirmEmailChangeRequest,
    ConfirmPasswordChangeRequest,
    ExportTicketsRequest,
    GetUserRequest,
    LoginRequest,
    LoginResponse,
    RequestEmailChangeRequest,
    RequestPasswordChangeRequest,
    RootState,
    SetPasswordRequest,
    SetPasswordResponse,
    UpdateTicketPlannedTimestampRequest,
    UpdateTicketStatusRequest,
} from './types'
import { Provider, Room, Ticket, Token, User } from 'common/types'
import {
    cleanAuthState,
    selectCurrentRefreshToken,
    setToken,
} from 'features/auth/authSlice'
import {
    BaseQueryError,
    BaseQueryResult,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { getTicketFileName } from '../features/dashboard/utils'

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
        const idToken = (getState() as RootState).auth.token?.id
        if (idToken) {
            headers.set('authorization', `Bearer ${idToken}`)
        }

        return headers
    },
})

const baseQueryReauth: BaseQueryFn<
    string | FetchArgs,
    BaseQueryResult<any>,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    let result: BaseQueryResult<any> = {}
    const refreshToken = selectCurrentRefreshToken(api.getState() as RootState)

    if (refreshToken) {
        const refreshResult: BaseQueryResult<any> = await baseQuery(
            {
                method: 'POST',
                url: '/auth/refresh-access-token',
                body: { refreshToken: refreshToken },
            },
            api,
            extraOptions
        )

        // store resulted token
        if (
            refreshResult &&
            refreshResult.data &&
            refreshResult.data.token &&
            refreshResult.data.token.access &&
            refreshResult.data.token.id
        ) {
            const token: Token = {
                refresh: refreshToken,
                access: refreshResult.data.token.access,
                id: refreshResult.data.token.id,
            }

            api.dispatch(setToken(token))
            result = await baseQuery(args, api, extraOptions)
        } else {
            api.dispatch(cleanAuthState())
            api.dispatch(resetApiState())
        }
        // endpoints which do not require tokens
    } else if (
        [
            'login',
            'requestPasswordChange',
            'setPassword',
            'confirmPasswordChange',
        ].includes(api.endpoint)
    ) {
        result = await baseQuery(args, api, extraOptions)
    }

    return result
}

const api = createApi({
    baseQuery: baseQueryReauth,
    tagTypes: ['Ticket'],
    endpoints: build => ({
        setPassword: build.mutation<SetPasswordResponse, SetPasswordRequest>({
            query: body => ({
                method: 'POST',
                url: '/auth/set-password',
                body,
            }),
        }),
        login: build.mutation<LoginResponse, LoginRequest>({
            query: body => ({
                method: 'POST',
                url: '/auth/login',
                body,
            }),
        }),
        changePassword: build.mutation<void, ChangePasswordRequest>({
            query: body => ({
                method: 'POST',
                url: '/auth/change-password',
                body,
            }),
        }),
        requestPasswordChange: build.mutation<
            void,
            RequestPasswordChangeRequest
        >({
            query: body => ({
                method: 'POST',
                url: '/auth/request-password-change',
                body,
            }),
        }),
        confirmPasswordChange: build.mutation<
            { message: string },
            ConfirmPasswordChangeRequest
        >({
            query: body => ({
                method: 'POST',
                url: '/auth/confirm-password-change',
                body,
            }),
        }),
        requestEmailChange: build.mutation<
            { message: string },
            RequestEmailChangeRequest
        >({
            query: body => ({
                method: 'PATCH',
                url: '/auth/request-email-change',
                body,
            }),
        }),
        confirmEmailChange: build.mutation<
            { message: string },
            ConfirmEmailChangeRequest
        >({
            query: body => ({
                method: 'POST',
                url: '/auth/confirm-email-change',
                body,
            }),
        }),
        getUser: build.mutation<User, GetUserRequest>({
            query: body => ({
                method: 'POST',
                url: '/auth/user',
                body,
            }),
        }),
        getProvider: build.mutation<Provider, string>({
            query: providerId => ({
                method: 'GET',
                url: `/provider/${providerId}`,
            }),
        }),
        getTicketsByProviderId: build.query<Ticket[], string>({
            query: providerId => ({
                method: 'GET',
                url: `/provider/${providerId}/ticket`,
            }),
            transformErrorResponse: (response: BaseQueryError<any>) => {
                return response
            },

            providesTags: result =>
                result
                    ? [
                          ...result.map(({ id }) => ({
                              type: 'Ticket' as const,
                              id,
                          })),
                          'Ticket',
                      ]
                    : ['Ticket'],
        }),
        getRoomsByProviderId: build.query<Room[], string>({
            query: providerId => ({
                method: 'GET',
                url: `/provider/${providerId}/room`,
            }),
        }),
        updateTicketStatus: build.mutation<void, UpdateTicketStatusRequest>({
            query: ({ providerId, ticketId, body }) => ({
                method: 'PATCH',
                url: `/provider/${providerId}/ticket/${ticketId}/update-status`,
                body,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Ticket', id: arg.ticketId },
            ],
        }),
        updateTicketPlannedTimestamp: build.mutation<
            void,
            UpdateTicketPlannedTimestampRequest
        >({
            query: ({ providerId, ticketId, body }) => ({
                method: 'PATCH',
                url: `/provider/${providerId}/ticket/${ticketId}/update-planned-timestamp`,
                body,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Ticket', id: arg.ticketId },
            ],
        }),
        exportTickets: build.mutation<any, ExportTicketsRequest>({
            query: ({ providerId, params }) => ({
                method: 'GET',
                url: `/provider/${providerId}/export-tickets?ticketIds=${
                    params.ticketIds
                }&exportFormat=${params.exportFormat.toLowerCase()}&indentation=${
                    params.indentation
                }`,
                responseHandler: async response => {
                    const href = URL.createObjectURL(await response.blob())

                    const link = document.createElement('a')
                    link.href = href
                    link.setAttribute(
                        'download',
                        getTicketFileName(params.exportFormat)
                    )
                    document.body.appendChild(link)
                    link.click()

                    document.body.removeChild(link)
                    URL.revokeObjectURL(href)
                },
                cache: 'no-cache',
            }),
        }),
    }),
})

const resetApiState = () => api.util.resetApiState()

export const {
    useSetPasswordMutation,
    useLoginMutation,
    useChangePasswordMutation,
    useRequestPasswordChangeMutation,
    useConfirmPasswordChangeMutation,
    useRequestEmailChangeMutation,
    useConfirmEmailChangeMutation,
    useGetUserMutation,
    useGetProviderMutation,
    useGetTicketsByProviderIdQuery,
    useLazyGetTicketsByProviderIdQuery,
    useGetRoomsByProviderIdQuery,
    useUpdateTicketStatusMutation,
    useUpdateTicketPlannedTimestampMutation,
    useExportTicketsMutation,
} = api

export default api
